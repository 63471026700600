var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.kidAthleteTableHeaders,"items":_vm.kidAthletes,"hide-default-footer":"","show-group-by":"","disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var groupBy = ref.groupBy;
var remove = ref.remove;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},'v-btn',attrs,false),on),[(isOpen)?_c('v-icon',[_vm._v("fa-minus")]):_c('v-icon',[_vm._v("fa-plus")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(isOpen ? "Összecsukás" : "Kinyitás")+" ")]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":remove}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-times")])],1)]}}],null,true)},[_vm._v(" A csoportosítás megszüntetése ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue"},on:{"click":function($event){_vm.dialogs.changeGroup.data.kidAthleteId=item.id, _vm.dialogs.changeGroup.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-pen")])],1)]}}],null,true)},[_vm._v(" Csoport módosítása ")])]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogs.changeGroup.show),callback:function ($$v) {_vm.$set(_vm.dialogs.changeGroup, "show", $$v)},expression:"dialogs.changeGroup.show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Csoport módosítása")])]),_c('v-card-text',[_c('v-container',[_c('v-select',{attrs:{"prepend-inner-icon":"fa-users","label":"Új csoport","items":_vm.groups,"rounded":"","outlined":""},model:{value:(_vm.dialogs.changeGroup.data.newGroupId),callback:function ($$v) {_vm.$set(_vm.dialogs.changeGroup.data, "newGroupId", $$v)},expression:"dialogs.changeGroup.data.newGroupId"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogs.changeGroup.show = false}}},[_vm._v(" Mégsem ")]),(_vm.dialogs.changeGroup.data.newGroupId)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.changeGroup()}}},[_vm._v(" Módosítás ")]):_vm._e()],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }