<template>
	<div>
		<template>
      <v-data-table
        :headers="kidAthleteTableHeaders"
				:items="kidAthletes"
				hide-default-footer
        show-group-by
				disable-pagination
      >
      <template v-slot:group.header="{ group, headers, toggle, isOpen, groupBy, remove }">
        <td :colspan="headers.length">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn @click="toggle" x-small icon v-on="on" v-bind="attrs" :ref="group">
                 <v-icon v-if="isOpen">fa-minus</v-icon>
                 <v-icon v-else>fa-plus</v-icon>
             </v-btn>
            </template>
            {{isOpen ? "Összecsukás" : "Kinyitás"}}
          </v-tooltip>
           <span class="mx-5 font-weight-bold">{{group}}</span>
           <v-tooltip top>
             <template v-slot:activator="{on, attrs}">
               <v-btn icon @click="remove" v-bind="attrs" v-on="on"><v-icon>fa-times</v-icon></v-btn>
             </template>
             A csoportosítás megszüntetése
           </v-tooltip>
        </td>
      </template>

        <template v-slot:item.actions="{item}">
          <v-tooltip top>

            <template v-slot:activator="{on, attrs}">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="dialogs.changeGroup.data.kidAthleteId=item.id, dialogs.changeGroup.show=true"
                icon
                color="blue"
              >
                <v-icon>fa-pen</v-icon>
              </v-btn>
            </template>
            Csoport módosítása
          </v-tooltip>
        </template>
      </v-data-table>
      <v-row justify="center">
		    <v-dialog
		      v-model="dialogs.changeGroup.show"
		      persistent
		      max-width="600px"
		    >

		      <v-card>
		        <v-card-title>
		          <span class="headline">Csoport módosítása</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-select
									prepend-inner-icon="fa-users"
									label="Új csoport"
									v-model="dialogs.changeGroup.data.newGroupId"
                  :items="groups"
									rounded
									outlined
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogs.changeGroup.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="changeGroup()"
								v-if="dialogs.changeGroup.data.newGroupId"
		          >
		            Módosítás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    </v-dialog>
	  </v-row>
	   </template>
	</div>
</template>

<script>
	export default {
		props: [],
		data: function(){
			return {
        kidAthletes: [],
        groups: [],
        dialogs: {
          changeGroup: {
            data: {
              kidAthleteId: null,
              newGroupId: null
            },
            show: false
          }
        },
        kidAthleteTableHeaders: [
          {
            text: "Név",
            align: "center",
            sortable: true,
            filterable: false,
            groupable: false,
            value: "fullname"
          },
          {
            text: "Csoport",
            align: "center",
            sortable: true,
            filterable: false,
            groupable: true,
            value: "group"
          },
          {
            text: "Születés ideje",
            align: "center",
            sortable: false,
            filterable: false,
            groupable: false,
            value: "birthdate"
          },
          {
            text: "Születési hely",
            align: "center",
            sortable: false,
            filterable: false,
            groupable: false,
            value: 'birthplace'
          },
          {
            text: "Műveletek",
            align: "center",
            sortable: false,
            filterable: false,
            groupable: false,
            value: "actions"
          }
        ]
      }
		},
		computed: {

		},
		watch: {

		},
		methods: {
      changeGroup: function() {
        this.axios({url: "kidathlete/changeGroup", method: "POST", data: this.dialogs.changeGroup.data}).then(response => {
          if(response.data.success) {
            this.dialogs.changeGroup.show=false;
            this.kidAthletes.find(KA => KA.id==this.dialogs.changeGroup.data.kidAthleteId).group=this.groups.find(G => G.value==this.dialogs.changeGroup.data.newGroupId).text
            this.$store.commit('setSnack','A módosítás sikeresen megtörtént.')
          }
        })
      },
      getKidAthletes: function() {
        this.axios({url: "kidathlete/list/club", method: "GET"}).then((response) => {
          if(response.data.success) {
            this.groups=response.data.data.groups.map(g => {
            return {
              text: `${g.name} - ${g.shortname} (${g.leader})`,
              value: g.id
            }
          })
            this.kidAthletes = response.data.data.kidAthletes;
          }
  			})
      }
		},
		mounted(){
		    this.getKidAthletes();
		}
	}
</script>
