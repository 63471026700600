<template>
	<div>
    <v-tabs icons-and-text>
      <v-tab>
        Csoportok módosítása
        <v-icon>
          fa-users
        </v-icon>
      </v-tab>
      <v-tab-item>
        <groups/>
      </v-tab-item>
    </v-tabs>


	</div>
</template>

<script>
	import router from '../router';
  import groups from '../components/kidAthleteManagement-Groups'
	export default {
    props: [],
		components: {
      groups
		},
		data: function(){
			return {

      }
		},
		computed: {

		},
		methods: {

		},
		mounted(){

		}
	}
</script>
